import { useTheme } from "@emotion/react";
import { isAddress } from "@ethersproject/address";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export const TransferNFT = ({ isOpen, handleClose, handleTransfer }) => {
  const [value, setValue] = useState("");
  const [isError, setIsError] = useState(false);

  const theme = useTheme();

  const handleTransferClick = () => {
    if (isAddress(value)) {
      handleTransfer(value);
      handleClose();
      setIsError(false);
      setValue("");
    } else {
      setIsError(true);
    }
  };

  const handleCloseClick = () => {
    setIsError(false);
    setValue("");
    handleClose();
  };

  const handleChange = ($event) => {
    setValue($event.target.value);
    if (isAddress($event.target.value)) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };
  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>
        <Typography variant="h6">Transfer Note</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          error={isError}
          sx={{ color: isError ? theme.palette.error.main : "black" }}
          fullWidth
          value={value}
          onChange={handleChange}
          placeholder="Address (i.e. 0x00jsbsy8n...)"
        />
        {isError && (
          <Typography
            sx={{ color: theme.palette.error.main }}
            variant="caption"
          >
            Enter a valid address.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button sx={{width:"100px"}}  variant="contained" color="secondary" onClick={handleTransferClick}>Transfer</Button>
        <Button  sx={{width:"100px"}}  variant="contained" color="primary" onClick={handleCloseClick}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
