import {  createSlice } from '@reduxjs/toolkit';
import { Network } from 'alchemy-sdk';
import { getAllNfts, refreshNftMetadata } from './thunks/myNotes.thunks';

const initialState = {
  notes: [],
  notesDetails: [],
  isLoading: false,
  error: {
    message: undefined,
    obj: undefined
  }
};

export const myNotesSlice = createSlice({
  name: 'myNotes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNotes: (state,action) => {
        state.notes = action.payload
    },
    setIsLoading: (state, action) => {
        state.isLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNfts.fulfilled, (state, action) => {
        const nftAddr = "0x9Bf58e1c4b588C1718425A2a5E3BdbC392878420";
        const data = action.payload.filter(nft => nft.contract.address.toLowerCase() === nftAddr.toLowerCase())
        state.notes = data;
        state.isLoading = false;
        state.error.message = undefined;
        state.error.obj = undefined
    })
    builder.addCase(getAllNfts.rejected, (state, action) => {
        state.isLoading = false;
        state.error.message = "Error loading your notes, Please try again.";
        state.error.obj = action.payload
    })
    builder.addCase(getAllNfts.pending, (state) => {
        state.isLoading = true;
        state.error.message = undefined;
        state.error.obj = undefined
    })
    builder.addCase(refreshNftMetadata.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(refreshNftMetadata.fulfilled, (state) => {
      state.isLoading = false;
    })
    builder.addCase(refreshNftMetadata.rejected, (state) => {
      state.isLoading = false;
    })
  }
  
});

export const { setNotes, setIsLoading } = myNotesSlice.actions;


export default myNotesSlice.reducer;