import { useTheme } from "@emotion/react";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";

import { useNavigate } from "react-router-dom";
import { TransferNFT } from "./send-dlg";
import { TxDialog } from "../../../components/tx-dialog";
import { useWeb3React } from "@web3-react/core";
import { getNetwork } from "../../../connectors/addresses";
import { Contract } from "@ethersproject/contracts";
import { useDispatch } from "react-redux";
import { getAllNfts } from "../../../app/thunks/myNotes.thunks";
import { ViewNoteDialog } from "./view-dlg";
import { EditNoteDialog } from "./edit-notes-dlg";

export const NoteCard = ({ note }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { account, chainId, library } = useWeb3React();
  const [isTransferOpen, setTransferOpen] = useState(false);
  const [txDlgIsOpen, setTxDlgIsOpen] = useState(false);
  const [isTxPending, setIsTxPending] = useState(false);
  const [isTxFailed, setIsTxFailed] = useState(false);
  const [isTxSuccess, setIsTxSuccess] = useState(false);
  const [txHash, setTxHash] = useState(undefined);
  const [viewIsOpen, setViewIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const handleTransferClose = () => {
    setTransferOpen(false);
  };

  const handleTransfer = async (address) => {
    setTxDlgIsOpen(true);
    setIsTxPending(true);
    try {
      const network = getNetwork(chainId);
      const signer = await library.getSigner();
      const contract = new Contract(network.CONTRACT, network.ABI, signer);
      const tx = await contract.transferFrom(account, address, note.tokenId);
      setTxHash(tx.hash);
      await tx.wait();
      setIsTxPending(false);
      setIsTxSuccess(true);
      dispatch(getAllNfts({ account, chainId }));
    } catch (err) {
      setIsTxPending(false);
      setIsTxFailed(true);
      console.log(err);
    }
  };

  const handleViewXferClick = () => {
    //setViewIsOpen(false);
    setTransferOpen(true);
  };
  const navigate = useNavigate();
  return (
    <Fragment>
      <Paper sx={{ p: 1 }} elevation={2} variant="elevation">
        <img
          onClick={() => {
            setViewIsOpen(true);
          }}
          width="100%"
          style={{ cursor: "pointer" }}
          src={note.rawMetadata.image}
          alt="Note item"
        />
        <Box width="100%" display="flex">
          <Typography
            onClick={() => {
              setViewIsOpen(true);
            }}
            sx={{
              fontWeight: 500,
              textTransform: "uppercase",
              cursor: "pointer",
            }}
            color={theme.palette.secondary.main}
          >
            {note.title}
          </Typography>
          <span style={{ flex: "1 auto" }}></span>
          <Typography
            color={theme.palette.secondary.main}
          >{`#${note.tokenId}`}</Typography>
        </Box>
        <Box width="100%" display="flex">
          <span style={{ flex: "1 auto" }}></span>
          <Tooltip title="View Note">
            <IconButton
              color="secondary"
              onClick={() => {
                setViewIsOpen(true);
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Note">
            <IconButton
              color="secondary"
              onClick={() => {
                setIsEditOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send Note">
            <IconButton
              color="secondary"
              onClick={() => {
                setTransferOpen(true);
              }}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Paper>
      <TransferNFT
        handleClose={handleTransferClose}
        handleTransfer={(addr) => handleTransfer(addr)}
        isOpen={isTransferOpen}
      />
      <TxDialog
        handleClose={() => setTxDlgIsOpen(false)}
        isFailed={isTxFailed}
        isOpen={txDlgIsOpen}
        isPending={isTxPending}
        isSuccessful={isTxSuccess}
        txHash={txHash}
      />
      <ViewNoteDialog
        handleTransfer={handleViewXferClick}
        handleEdit={() => {
          setIsEditOpen(true);
          setViewIsOpen(false);
        }}
        note={note}
        isOpen={viewIsOpen}
        handleClose={() => setViewIsOpen(false)}
      />
      <EditNoteDialog
        note={note}
        handleClose={() => {
          setIsEditOpen(false);
        }}
        isOpen={isEditOpen}
      />
    </Fragment>
  );
};
