import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { NoteCard } from "./components/note-card";
import { useWeb3React } from "@web3-react/core";
import { getAllNfts } from "../../app/thunks/myNotes.thunks";
export const MyNotesPage = () => {
  const notes = useSelector((state) => state.myNotes.notes);
  const { account, chainId } = useWeb3React();
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Box display="flex">
        <span style={{ flex: " 1 auto" }}></span>
        <Button
          onClick={() => {
            if (account && chainId) {
              dispatch(getAllNfts({ account, chainId }));
            }
          }}
          endIcon={<RefreshIcon />}
          variant="contained"
          color="secondary"
        >
          Refresh Notes
        </Button>
      </Box>
      <Grid sx={{ mt: 4 }} container spacing={2}>
        {notes.length > 0 &&
          notes.map((note, index) => {
            return (
              <Grid key={`item_${index}`} item lg={3} md={4} sm={12} xs={12}>
                <NoteCard note={note} />
              </Grid>
            );
          })}
        {account && notes.length === 0 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              mt:5
            }}
          >
            <Typography variant="h3" color="GrayText">{`You have not created any notes.`} </Typography>
          </Box>
        )}
        {!account  && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              mt:5
            }}
          >
            <Typography variant="h3" color="GrayText">{`Please connect your Metamask wallet.`} </Typography>
          </Box>
        )}
      </Grid>
    </Fragment>
  );
};
