import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import { GridLoader } from "react-spinners";
import successGif from "../assets/success.gif";
import errorGif from "../assets/close.gif";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

export const TxDialog = ({
  handleClose,
  isOpen,
  isPending,
  isSuccessful,
  isFailed,
  txHash,
}) => {
  const { chainId } = useWeb3React();
  const navigate =useNavigate();
  const theme = useTheme();
  return (
    <Dialog maxWidth={"xs"} fullWidth open={isOpen}>
      <DialogTitle>Transaction Status</DialogTitle>
      <DialogContent>
        <Box
          sx={{ pt: 2, pb: 2 }}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          {isPending && <GridLoader color={theme.palette.secondary.dark} />}
          {isSuccessful && (
            <img
              style={{ width: "80px", height: "80px" }}
              src={successGif}
              alt="operation successful"
            />
          )}
          {isFailed && (
            <img
              style={{ width: "80px", height: "80px" }}
              src={errorGif}
              alt="operation successful"
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          {isPending && (
            <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
              Transaction Pending
            </Typography>
          )}
          {isFailed && (
            <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
              Transaction Failed
            </Typography>
          )}
          {isSuccessful && (
            <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
              Transaction Successful
            </Typography>
          )}
        </Box>
        {txHash && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <a
              href={`${getexplorerUrl(chainId)}${txHash}`}
              target="_blank"
              rel="noreferrer"
              style={{color: theme.palette.secondary.dark}}
            >
              <Typography>View on Explorer</Typography>
            </a>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3 }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          {isSuccessful && (
            <Button
              sx={{ ml: 1, mr: 1, width: "120px" }}
              variant="contained"
              color="secondary"

              onClick={() => {
                navigate("/dashboard")
              }}
            >
              Dashboard
            </Button>
          )}
          {(isSuccessful || isFailed) && (
            <Button
              sx={{ ml: 1, mr: 1, width: "120px" }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

function getexplorerUrl(chainId) {
  switch (Number(chainId)) {
    case 1:
      return "https://etherscan.io/tx/";
    case 137:
      return "https://polygonscan.com/tx/";
    case 5:
      return "https://goerli.etherscan.io/tx/";
    case 42161:
      return "https://arbiscan.io/tx/";
    case 421613:
      return "https://goerli.arbiscan.io/tx/";
    default:
      return "https://etherscan.io/tx";
  }
}
