import { abi } from "./abi";
import { Network } from "alchemy-sdk";
const NETWORKS = {
  ETH: {
    RPC: process.env.REACT_APP_ETH_RPC,
    KEY: process.env.REACT_APP_ALCHEMY_API_KEY,
    CONTRACT: "0x9Bf58e1c4b588C1718425A2a5E3BdbC392878420",
    ABI: abi,
    AlchemyNetwork: Network.ETH_MAINNET
  },
  ARBITRUM: {
    RPC: process.env.REACT_APP_ARB_RPC,
    KEY: process.env.REACT_APP_ALCHEMY_API_KEY,
    CONTRACT: "0x9Bf58e1c4b588C1718425A2a5E3BdbC392878420",
    ABI: abi,
    AlchemyNetwork: Network.ARB_MAINNET
  },
  POLYGON: {
    RPC: process.env.REACT_APP_POLYGON_RPC,
    KEY: process.env.REACT_APP_ALCHEMY_API_KEY,
    CONTRACT: "0x9Bf58e1c4b588C1718425A2a5E3BdbC392878420",
    ABI: abi,
    AlchemyNetwork: Network.MATIC_MAINNET
  },
};

export const getNetwork = (chainId) => {
  switch (chainId) {
    case Number(process.env.REACT_APP_ETH_CHAIN_ID):
      return NETWORKS.ETH;
    case Number(process.env.REACT_APP_ARB_CHAIN_ID):
      return NETWORKS.ARBITRUM;
    case Number(process.env.REACT_APP_POLYGON_CHAIN_ID):
        return NETWORKS.POLYGON;
    default:
      return null;
  }
};
