import React, { Fragment, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  InputAdornment,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { GithubPicker, SketchPicker } from "react-color";
import { useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { getNetwork } from "../../../connectors/addresses";
import { Contract } from "@ethersproject/contracts";
import { TxDialog } from "../../../components/tx-dialog";
import { useDispatch } from "react-redux";
import { refreshNftMetadata } from "../../../app/thunks/myNotes.thunks";

import { FormatColorFill, InsertEmoticon } from "@mui/icons-material";
import { EmojiDialog } from "../../../shared/emoji-dlg";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EditNotes = ({ note }) => {
  const [previewDimension, setPreviewDimension] = useState("100%");
  const [svgBg, setSvgBg] = useState("yellow");
  const [svgText, setSvgText] = useState(
    "The quick brown fox jumps over the lazy dog. Pack my box with five dozen liquor jugs"
  );
  const [fontSize, setFontSize] = useState(20);
  const [svgColor, setSvgColor] = useState("#000");
  const [fontWeight, setFotWeight] = useState(500);
  const [fontFamily, setFontFamily] = useState(`'Permanent Marker', cursive`);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [noteName, setNoteName] = useState("NFT Note");
  const [txDlgIsOpen, setTxDlgIsOpen] = useState(false);
  const [isTxPending, setIsTxPending] = useState(false);
  const [isTxFailed, setIsTxFailed] = useState(false);
  const [isTxSuccess, setIsTxSuccess] = useState(false);
  const [txHash, setTxHash] = useState(undefined);
  const [textColorPicker, setTextColorPicker] = useState(false);
  const [bgColorPicker, setBgColorPicker] = useState(false);
  const [openEmojiDlg, setOpenEmojiDlg] = useState(false);
  useEffect(() => {
    if (note) {
      const { bgColor, fontSize, fontWeight, txtColor, fontFamily } =
        getAttributes(note.rawMetadata.attributes);
      setFontSize(fontSize);
      setFotWeight(fontWeight);
      setNoteName(note.title);
      setSvgBg(bgColor);
      setSvgColor(txtColor);
      setSvgText(note.rawMetadata.noteText);
      if (fontFamily) {
        setFontFamily(fontFamily);
        console.log("Font Family", fontFamily);
      }
    }
  }, [note]);
  const { chainId, account, library } = useWeb3React();
  const dispatch = useDispatch();

  const handleSvgTextChange = (e) => {
    setSvgText(e.target.value.replaceAll(/(?:\r\n|\r|\n)/g, "<br//>"));
  };
  const handlePreviewOpen = () => {
    setPreviewOpen(true);
    setPreviewDimension(1200);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewDimension("100%");
  };
const navigate = useNavigate();
  const handleMint = async () => {
    setTxDlgIsOpen(true);
    setIsTxPending(true);
    let imageCode = "Image";
    imageCode = document.getElementById("svgCont2").innerHTML;

    const metadata = createMetadata(
      noteName,
      imageCode.replaceAll("<br>", "<br/>"),
      svgText,
      svgBg,
      svgColor,
      fontSize,
      fontWeight,
      fontFamily
    );
    const blob = new Blob([JSON.stringify(metadata)], {
      type: "application/json",
    });
    const form = new FormData();
    form.append("file", blob);
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_PINATA_API_JWT}`, // to prevent abuse JWT key will be moved to Factory contract
      "Content-type": "multipart/form-data",
    };
    try {
      const ipfsResult = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        form,
        { headers: headers }
      );
      console.log(ipfsResult.data.IpfsHash);
      const network = getNetwork(chainId);
      const signer = await library.getSigner();
      const contract = new Contract(network.CONTRACT, network.ABI, signer);
      const price = await contract.REWRITE_PRICE();
      const overRides = {
        value: price,
      };
      const txResult = await contract.reWriteNote(
        ipfsResult.data.IpfsHash,
        Number(note.tokenId),
        overRides
      );

      setTxHash(txResult.hash);
      await txResult.wait();

      setIsTxPending(false);
      setIsTxSuccess(true);
    } catch (err) {
      console.log("Mint exception:", err);
      setIsTxPending(false);
      setIsTxSuccess(false);
      setIsTxFailed(true);
    }
    dispatch(refreshNftMetadata({ tokenId: note.tokenId, chainId, account }));
  };
  const svgImage = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={previewDimension}
      height={previewDimension}
    >
      <foreignObject
        class="node"
        x="0"
        y="0"
        width={previewDimension}
        height={previewDimension}
      >
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Sofia+Sans:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000');
          @import
          url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Anton&family=Bebas+Neue&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Courgette&family=Creepster&family=Dancing+Script:wght@400;500;600;700&family=Fredoka+One&family=Great+Vibes&family=Indie+Flower&family=Julee&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lobster&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+HK:wght@100;300;400;500;700;900&family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans+SC:wght@100;300;400;500;700;900&family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&family=Orbitron:wght@400;500;600;700;800;900&family=PT+Sans+Narrow:wght@400;700&family=Pacifico&family=Permanent+Marker&family=Playball&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Rokkitt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Russo+One&family=Satisfy&family=Secular+One&family=Shadows+Into+Light&family=Share+Tech+Mono&family=Short+Stack&family=Six+Caps&family=Space+Grotesk:wght@300;400;500;600;700&family=Stint+Ultra+Condensed&family=Teko:wght@300;400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Zeyada');
        </style>
        <body xmlns="http://www.w3.org/1999/xhtml">
          <div
            style={{
              backgroundColor: svgBg,
              padding: "30px",
              height: previewDimension,
            }}
          >
            <p
              style={{
                color: svgColor,
                fontSize: `${fontSize}px`,
                margin: 0,
                fontWeight: fontWeight,
                fontFamily: `${fontFamily}`,
              }}
              dangerouslySetInnerHTML={{ __html: svgText }}
            ></p>
          </div>
        </body>
      </foreignObject>
    </svg>
  );

  const svgImageActual = (
    <svg xmlns="http://www.w3.org/2000/svg" width={1200} height={1200}>
      <foreignObject class="node" x="0" y="0" width={1200} height={1200}>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Sofia+Sans:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000');
          @import
          url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Anton&family=Bebas+Neue&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Courgette&family=Creepster&family=Dancing+Script:wght@400;500;600;700&family=Fredoka+One&family=Great+Vibes&family=Indie+Flower&family=Julee&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lobster&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+HK:wght@100;300;400;500;700;900&family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Noto+Sans+SC:wght@100;300;400;500;700;900&family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&family=Orbitron:wght@400;500;600;700;800;900&family=PT+Sans+Narrow:wght@400;700&family=Pacifico&family=Permanent+Marker&family=Playball&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Rokkitt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Russo+One&family=Satisfy&family=Secular+One&family=Shadows+Into+Light&family=Share+Tech+Mono&family=Short+Stack&family=Six+Caps&family=Space+Grotesk:wght@300;400;500;600;700&family=Stint+Ultra+Condensed&family=Teko:wght@300;400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Zeyada');
        </style>
        <body
          xmlns="http://www.w3.org/1999/xhtml"
          style={{ height: 1200, width: 1200 }}
        >
          <div
            style={{
              backgroundColor: svgBg,
              padding: "30px",
              height: previewDimension,
            }}
          >
            <p
              style={{
                color: svgColor,
                fontSize: `${fontSize}px`,
                margin: 0,
                fontWeight: fontWeight,
                fontFamily: `${fontFamily}`,
              }}
              dangerouslySetInnerHTML={{ __html: svgText }}
            ></p>
          </div>
        </body>
      </foreignObject>
    </svg>
  );
  return (
    <Fragment>
      <Grid sx={{ mt: 9 }} container spacing={2}>
        <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
          <Paper sx={styles.rootPaper}>
            <Typography sx={styles.heading} color="secondary" variant="h4">
              EDIT NFT NOTES
            </Typography>
            <Box display={'flex'} flexDirection="row" justifyContent={"center" } alignItems="center">
            <Typography sx={{fontSize: '25px'}} color="white" variant="h4">
              A simple NFT utility for anyone.
            </Typography>
            <Button onClick={() => navigate("/about")} color="secondary" sx={{ fontSize: '18px', ml:3, textTransform: "capitalize"}}>About</Button>
            </Box>
            <Box sx={styles.contentBox}>
              <Grid container spacing={0}>
                <Grid item lg={6} md={4}>
                  <Box sx={styles.metaBox}>
                    <Typography variant="body2">
                      Enter name for your NFT note
                    </Typography>
                    <TextField
                      id="outlined-textarea"
                      placeholder="Enter name"
                      fullWidth
                      value={noteName}
                      onChange={(e) => setNoteName(e.target.value)}
                    />
                    <br />
                    <Typography variant="body2">
                      Enter the text for the NFT notes
                    </Typography>
                    <TextField
                      id="outlined-textarea"
                      placeholder="Enter your text"
                      multiline
                      fullWidth
                      value={svgText.replaceAll("<br//>", "\n")}
                      onChange={handleSvgTextChange}
                      rows={8}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              mt:20
                            }}
                            position="bottom"
                          >
                            <IconButton onClick={() => setOpenEmojiDlg(true)}>
                            <InsertEmoticon  />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <br />
                    <Box sx={styles.fontBox}>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography sx={{ mb: 1 }} variant="body2">
                          Select Background Color
                        </Typography>
                        <GithubPicker
                          color={svgBg}
                          onChangeComplete={(color) => setSvgBg(color.hex)}
                        />
                      </div>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography sx={{ mb: 1 }} variant="body2">
                          Enter color hex code
                        </Typography>
                        <TextField
                          value={svgBg}
                          onChange={(e) => setSvgBg(e.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography sx={{ mb: 1 }} variant="body2">
                          &nbsp;
                        </Typography>
                        <IconButton>
                          <FormatColorFill
                            onClick={() => setBgColorPicker(true)}
                          />
                        </IconButton>
                      </div>
                    </Box>
                    <br />
                    <Box sx={styles.fontBox}>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography sx={{ mb: 1 }} variant="body2">
                          Select Text Color
                        </Typography>
                        <GithubPicker
                          colors={[
                            "#fff",
                            "#000",
                            "#fccb00",
                            "#b80000",
                            "#db3e00",
                            "#008b02",
                            "#006b76",
                            "#1273de",
                            "#004dcf",
                            "#eb9694",
                            "#fad0c3",
                            "#fef3bd",
                            "#c4def6",
                            "#d4c4fb",
                          ]}
                          color={svgColor}
                          onChangeComplete={(color) => setSvgColor(color.hex)}
                        />
                      </div>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography sx={{ mb: 1 }} variant="body2">
                          Enter color hex code
                        </Typography>
                        <TextField
                          value={svgColor}
                          onChange={(e) => setSvgColor(e.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography sx={{ mb: 1 }} variant="body2">
                          &nbsp;
                        </Typography>
                        <IconButton>
                          <FormatColorFill
                            onClick={() => setTextColorPicker(true)}
                          />
                        </IconButton>
                      </div>
                    </Box>
                    <br />
                    <Box sx={styles.fontBox}>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography>Text Font Size</Typography>
                        <Select
                          fullWidth
                          value={fontSize}
                          onChange={(e) => setFontSize(e.target.value)}
                        >
                          <MenuItem value={12}>12px</MenuItem>
                          <MenuItem value={16}>16px</MenuItem>
                          <MenuItem value={20}>20px</MenuItem>
                          <MenuItem value={24}>24px</MenuItem>
                          <MenuItem value={28}>28px</MenuItem>
                          <MenuItem value={32}>32px</MenuItem>
                          <MenuItem value={36}>36px</MenuItem>
                          <MenuItem value={40}>40px</MenuItem>
                          <MenuItem value={44}>44px</MenuItem>
                          <MenuItem value={48}>48px</MenuItem>
                          <MenuItem value={52}>52px</MenuItem>
                          <MenuItem value={56}>56px</MenuItem>
                          <MenuItem value={60}>60px</MenuItem>
                          <MenuItem value={64}>64px</MenuItem>
                          <MenuItem value={68}>68px</MenuItem>
                          <MenuItem value={72}>72px</MenuItem>
                        </Select>
                      </div>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography>Text Font Weight</Typography>
                        <Select
                          fullWidth
                          value={fontWeight}
                          onChange={(e) => setFotWeight(e.target.value)}
                        >
                          <MenuItem value={100}>Thin</MenuItem>
                          <MenuItem value={300}>Light</MenuItem>
                          <MenuItem value={400}>Regular</MenuItem>
                          <MenuItem value={500}>Medium</MenuItem>
                          <MenuItem value={700}>Bold</MenuItem>
                          <MenuItem value={900}>Black</MenuItem>
                        </Select>
                      </div>
                      <div style={{ width: "100%", marginRight: "8px" }}>
                        <Typography>Text Font Family</Typography>
                        <Select
                          fullWidth
                          value={fontFamily}
                          onChange={(e) => setFontFamily(e.target.value)}
                        >
                          <MenuItem value={`'Amatic SC', cursive`}>
                            Amatic SC
                          </MenuItem>
                          <MenuItem value={`'Anton', sans-serif`}>
                            Anton
                          </MenuItem>
                          <MenuItem value={`'Bebas Neue', cursive`}>
                            Bebas Neue
                          </MenuItem>
                          <MenuItem value={`'Cairo', sans-serif`}>
                            Cairo
                          </MenuItem>
                          <MenuItem value={`'Courgette', cursive`}>
                            Courgette
                          </MenuItem>
                          <MenuItem value={`'Creepster', cursive`}>
                            Creepster
                          </MenuItem>
                          <MenuItem value={`'Dancing Script', cursive`}>
                            Dancing Script
                          </MenuItem>
                          <MenuItem value={`'Fredoka One', cursive`}>
                            Fredoka One
                          </MenuItem>
                          <MenuItem value={`'Great Vibes', cursive`}>
                            Great Vibes
                          </MenuItem>
                          <MenuItem value={`'Indie Flower', cursive`}>
                            Indie Flower
                          </MenuItem>
                          <MenuItem value={`'Julee', cursive`}>Julee</MenuItem>
                          <MenuItem value={`'Kanit', sans-serif`}>
                            Kanit
                          </MenuItem>
                          <MenuItem value={`'Lobster', cursive`}>
                            Lobster
                          </MenuItem>
                          <MenuItem value={`'Merriweather', serif`}>
                            Merriweather
                          </MenuItem>
                          <MenuItem value={`'Noto Sans Arabic', sans-serif`}>
                            Noto Sans Arabic
                          </MenuItem>
                          <MenuItem value={`'Noto Sans HK', sans-serif`}>
                            Noto Sans HK
                          </MenuItem>
                          <MenuItem value={`'Noto Sans JP', sans-serif`}>
                            Noto Sans JP
                          </MenuItem>
                          <MenuItem value={`'Noto Sans KR', sans-serif`}>
                            Noto Sans KR
                          </MenuItem>
                          <MenuItem value={`'Noto Sans SC', sans-serif`}>
                            Noto Sans SC
                          </MenuItem>
                          <MenuItem value={`'Noto Sans TC', sans-serif`}>
                            Noto Sans TC
                          </MenuItem>
                          <MenuItem value={`'Noto Sans Thai', sans-serif`}>
                            Noto Sans Thai
                          </MenuItem>
                          <MenuItem value={`'Orbitron', sans-serif`}>
                            Orbitron
                          </MenuItem>
                          <MenuItem value={`'Oswald', sans-serif`}>
                            Oswald
                          </MenuItem>
                          <MenuItem value={`'Pacifico', cursive`}>
                            Pacifico
                          </MenuItem>
                          <MenuItem value={`'Permanent Marker', cursive`}>
                            Permanent Marker
                          </MenuItem>
                          <MenuItem value={`'Playball', cursive`}>
                            Playball
                          </MenuItem>
                          <MenuItem value={`'PT Sans Narrow', sans-serif`}>
                            PT Sans Narrow
                          </MenuItem>
                          <MenuItem value={`'Raleway', sans-serif`}>
                            Raleway
                          </MenuItem>
                          <MenuItem value={`'Red Hat Display', sans-serif`}>
                            Red Hat Display
                          </MenuItem>
                          <MenuItem value={`'Roboto Condensed', sans-serif`}>
                            Roboto Condensed
                          </MenuItem>
                          <MenuItem value={`'Rokkitt', serif`}>
                            Rokkitt
                          </MenuItem>
                          <MenuItem value={`'Rubik', sans-serif`}>
                            Rubik
                          </MenuItem>
                          <MenuItem value={`'Russo One', sans-serif`}>
                            Russo One
                          </MenuItem>
                          <MenuItem value={`'Satisfy', cursive`}>
                            Satisfy
                          </MenuItem>
                          <MenuItem value={`'Secular One', sans-serif`}>
                            Secular One
                          </MenuItem>
                          <MenuItem value={`'Shadows Into Light', cursive`}>
                            Shadows Into Light
                          </MenuItem>
                          <MenuItem value={`'Share Tech Mono', monospace`}>
                            Share Tech Mono
                          </MenuItem>
                          <MenuItem value={`'Short Stack', cursive`}>
                            Short Stack
                          </MenuItem>
                          <MenuItem value={`'Six Caps', sans-serif`}>
                            Six Caps
                          </MenuItem>
                          <MenuItem value={`'Sofia Sans', sans-serif`}>
                            Sofia Sans
                          </MenuItem>
                          <MenuItem value={`'Space Grotesk', sans-serif`}>
                            Space Grotesk
                          </MenuItem>
                          <MenuItem value={`'Stint Ultra Condensed', cursive`}>
                            Stint Ultra Condensed
                          </MenuItem>
                          <MenuItem value={`'Teko', sans-serif`}>Teko</MenuItem>
                          <MenuItem value={`'Ubuntu', sans-serif`}>
                            Ubuntu
                          </MenuItem>
                          <MenuItem value={`'Zeyada', cursive`}>
                            Zeyada
                          </MenuItem>
                        </Select>
                      </div>
                    </Box>
                    <br />
                    <Box>
                      <Button
                        sx={{ mr: 1, width: "175px" }}
                        variant="contained"
                        color="secondary"
                        onClick={handlePreviewOpen}
                      >
                        Preview NFT NOTE
                      </Button>
                      <Button
                        sx={{ mr: 0, width: "175px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleMint}
                      >
                        Save NFT Note
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6} md={8}>
                  <Box sx={styles.mintBox}>
                    <Typography sx={{ pb: 3 }} color="red" variant="caption">
                      <b>Note:</b> The below image is only a visualization of
                      your notes. Actual image will be created on a 1200x1200
                      size canvas and may look slightly different.
                      <br />
                      Check accurate note image using the <b>Preview</b> button.
                    </Typography>
                    <br />
                    <div
                      id="svgCont"
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "16px",
                      }}
                    >
                      {svgImage}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="sm"
        open={bgColorPicker}
        onClose={(x) => {
          console.log(x);
          setBgColorPicker(false);
        }}
        TransitionComponent={Transition}
      >
        <SketchPicker
          color={svgBg}
          onChangeComplete={(color) => {
            setSvgBg(color.hex);
            setBgColorPicker(false);
          }}
        />
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={textColorPicker}
        onClose={(x) => {
          console.log(x);
          setTextColorPicker(false);
        }}
        TransitionComponent={Transition}
      >
        <SketchPicker
          color={svgColor}
          onChangeComplete={(color) => {
            setSvgColor(color.hex);
            setTextColorPicker(false);
          }}
        />
      </Dialog>
      <Dialog
        fullScreen
        open={previewOpen}
        onClose={handlePreviewClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlePreviewClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
            <Button autoFocus color="inherit" onClick={handlePreviewClose}>
              OK
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={4}>
          <Grid item xm={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                alignItems: "center",
                width: "100%",
                mt: 3,
                mb: 3,
                justifyContent: "ceter",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {svgImage}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      <div
        id="svgCont2"
        style={{
          height: "100%",
          width: "100%",
          marginTop: "16px",
          display: "none",
        }}
      >
        {svgImageActual}
      </div>

      <TxDialog
        handleClose={() => {
          setTxDlgIsOpen(false);
          setIsTxPending(false);
          setTxHash(undefined);
          setIsTxFailed(false);
          setIsTxSuccess(false);
        }}
        isOpen={txDlgIsOpen}
        isPending={isTxPending}
        isSuccessful={isTxSuccess}
        isFailed={isTxFailed}
        txHash={txHash}
      />

      <EmojiDialog
        isOpen={openEmojiDlg}
        onEmojiClick={(val) => {
          setSvgText(`${svgText}${val}`);
        }}
        closeDlg={() => setOpenEmojiDlg(false)}
      />
    </Fragment>
  );
};

const styles = {
  rootPaper: {
    p: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontWeight: 700,
  },
  img: {
    width: "auto",
    height: 400,
  },
  contentBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  metaBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    p: 5,
    mt: 3,
  },
  mintBox: {
    p: 3,
    mt: 3,
    height: "100%",
  },
  mintSelector: {
    width: "250px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    width: "400px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  svgBackground: {
    backgroundColor: "yellow",
  },

  fontBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "100%",
  },
};

const createMetadata = (
  name,
  image,
  text,
  backgroundColor,
  textColor,
  textSize,
  textWeight,
  fontFamily
) => {
  const metadata = {
    name: name,
    description:
      "NFT Notes are customizable messages you can send to any NFT wallet!",
    noteText: text,
    image: `data:image/svg+xml;base64,${btoa(
      unescape(encodeURIComponent(image))
    )}`,
    attributes: [
      {
        trait_type: "Background Color",
        value: backgroundColor,
      },
      {
        trait_type: "Text Color",
        value: textColor,
      },
      {
        trait_type: "Font Size",
        value: textSize,
      },
      {
        trait_type: "Font Weight",
        value: textWeight,
      },
      {
        trait_type: "Font Family",
        value: fontFamily,
      },
    ],
  };
  return metadata;
};

const getAttributes = (attributesArray) => {
  let attributes = {
    bgColor: undefined,
    txtColor: undefined,
    fontSize: undefined,
    fontWeight: undefined,
  };
  attributesArray.forEach((item) => {
    switch (item.trait_type) {
      case "Background Color":
        attributes = { ...attributes, bgColor: item.value };
        break;
      case "Text Color":
        attributes = { ...attributes, txtColor: item.value };
        break;
      case "Font Size":
        attributes = { ...attributes, fontSize: item.value };
        break;
      case "Font Weight":
        attributes = { ...attributes, fontWeight: item.value };
        break;
      case "Font Family":
        attributes = { ...attributes, fontFamily: item.value };
        break;
      default:
        break;
    }
  });
  return attributes;
};
