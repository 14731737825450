import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from "@mui/material";
import { Close, Edit, ExpandMore, Send } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

export const ViewNoteDialog = ({
  note,
  isOpen,
  handleClose,
  handleTransfer,
  handleEdit,
}) => {

  const [imageSvg, setImageSvg] = useState("");
  const handleCloseClick = () => {
    handleClose();
  };

  useEffect(() => {
    if(note){
      setImageSvg(decodeURIComponent(escape(window.atob(note.rawMetadata.image.split('base64,')[1]))).replaceAll('width="1200"', 'width="100%"'));
    }
  }, [note])
  return (
    <Dialog fullScreen handleClose={handleCloseClick} open={isOpen}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseClick}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`${note.title} #${note.tokenId}`}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleCloseClick}>
            OK
          </Button>
        </Toolbar>
      </AppBar>
      <Grid sx={{ mt: 9, mb: 6, pl: 2, pr: 2 }} container spacing={2}>
        <Grid item lg={6} sm={12} md={6}>
          <Paper sx={{ p: 1, pr: 2 }} variant="elevation" elevation={2}>
            <div width="100%" dangerouslySetInnerHTML={{__html: imageSvg}}>

            </div>
            
          </Paper>
        </Grid>
        <Grid item lg={6} sm={6} md={6}>
          <Paper sx={{ p: 1, pr: 2 }} elevation={2} variant="elevation">
            <Box sx={{ mt: 1 }}  width="100%">
              <Typography sx={{ fontSize: "25px", fontWeight: 700, pl: 1 }}>
                {note.title}
              </Typography>
              <Typography
                sx={{ fontSize: "15px", fontWeight: 400, pl: 1 }}
              >{`TOKEN ID: #${note.tokenId}`}</Typography>
            </Box>
            <Box sx={{ mt: 1 }} display={"flex"} width="100%">
              <Typography sx={{ fontSize: "18px", fontWeight: 300, pl: 1 }}>
                {note.rawMetadata.description}
              </Typography>
            </Box>
            
            <Box sx={{mt:4, mb:3}} display="flex" width="100%">
            <Button onClick={handleEdit} sx={{ml:1, mr: 1}} variant="contained" color="secondary" endIcon={<Edit />}>Edit</Button>
            <Button onClick={handleTransfer} sx={{ml:1, mr: 1}}  variant="contained" color="secondary" endIcon={<Send />}>Transfer</Button>
            </Box>
            <Box sx={{ mt: 1, pl: 1, mb: 1 }} display={"flex"} width="100%">
              <Accordion sx={{ width: "100%" }} variant="outlined">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                    }}
                  >
                    Attributes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {note.rawMetadata.attributes &&
                    note.rawMetadata.attributes.map((attribute, index) => {
                      return (
                        <Chip
                          sx={{ mr: 1 }}
                          color="secondary"
                          variant="filled"
                          key={`attribute_${index}`}
                          label={`${attribute.trait_type} : ${attribute.value}`}
                        />
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
};
