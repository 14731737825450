import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOpen } from "../../app/app-slice";
import { Info, Logout, NoteAdd, Notes } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import mintSvg from "../../assets/mint.svg";
import { setNotes } from "../../app/my-notes-slice";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const SideBar = () => {
  const isSidebarOpen = useSelector((state) => state.app.sidebarOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deactivate, account } = useWeb3React();
  const theme = useTheme();
  return (
    <Drawer variant="permanent" open={isSidebarOpen}>
      <DrawerHeader>
        <IconButton
          color="secondary"
          onClick={() => dispatch(setSidebarOpen(false))}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem color="secondary" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isSidebarOpen ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isSidebarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <NoteAdd color="secondary" />
            </ListItemIcon>
            <ListItemText
              color={theme.palette.secondary.main}
              primary={"Mint NFT Notes"}
              sx={{
                opacity: isSidebarOpen ? 1 : 0,
                color: theme.palette.secondary.main,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem color="secondary" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => {
              navigate("/dashboard");
            }}
            sx={{
              minHeight: 48,
              justifyContent: isSidebarOpen ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isSidebarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Notes color="secondary" />
            </ListItemIcon>
            <ListItemText
              color={theme.palette.secondary.main}
              primary={"My Notes"}
              sx={{
                opacity: isSidebarOpen ? 1 : 0,
                color: theme.palette.secondary.main,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem color="secondary" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => {
              navigate("/about");
            }}
            sx={{
              minHeight: 48,
              justifyContent: isSidebarOpen ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isSidebarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Info color="secondary" />
            </ListItemIcon>
            <ListItemText
              color={theme.palette.secondary.main}
              primary={"About"}
              sx={{
                opacity: isSidebarOpen ? 1 : 0,
                color: theme.palette.secondary.main,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {account && (
          <ListItem color="secondary" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: isSidebarOpen ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                deactivate();
                dispatch(setNotes([]))
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isSidebarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Logout color="secondary" />
              </ListItemIcon>
              <ListItemText
                color={theme.palette.secondary.main}
                primary={"Logout"}
                sx={{
                  opacity: isSidebarOpen ? 1 : 0,
                  color: theme.palette.secondary.main,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
