
import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { Fragment } from "react";

export const AboutPage = () => {
  
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
          <Paper sx={styles.rootPaper}>
            <Typography sx={styles.heading} color="secondary" variant="h4">
              NFT NOTES
            </Typography>
            <Box
              display={"flex"}
              flexDirection="row"
              justifyContent={"center"}
              alignItems="center"
            >
              <Typography
                sx={{ fontSize: "25px", mt: 1 }}
                color="white"
                variant="h4"
              >
                A simple NFT utility for anyone.
              </Typography>
            </Box>
            <Box sx={styles.contentBox}>
              <Grid container spacing={0}>
                <Grid item lg={12} md={12}>
                  <p style={styles.para}>
                    This project was created in 2022 with the goal in mind of
                    improving communication infastructure between both known and
                    unknown wallets. It has been historically difficult to send
                    simple messages to anon wallets, which can be a problem if
                    they have an NFT or other asset you are interested in, or if
                    you suspect it was stolen. We aim to simplify that process
                    and make it available to anyone. Of course, NFT notes are
                    also useful to storing low-security information on-chain in
                    a simple format.
                  </p>

                  <p style={styles.para}>
                    Notes can currently be minted for 0.01 ETH/10 Matic, and can
                    be re-written for 0.002 ETH / 2 Matic. Prices are intended
                    to be low and may be reduced in the future if ETH price
                    increases, to keep NFT notes accessible to anyone.
                  </p>
                  <p style={styles.para}>
                  In addition to stylistic improvements, we also envision further similar applications in the long term with some modification:
                  <ul>
                    <li>Holiday & Birthday "NFT Cards"</li>
                    <li> Notes that store important Data & Records</li>
                    <li> Student records (identify encoded or abstracted)</li>
                    <li>Medical records (identify encoded or abstracted)</li>
                    <li>More ideas in the pipeline</li>
                  </ul>
                  </p>

                  <p style={styles.para}>
                    Notes can currently be minted for 0.01 ETH/10 Matic, and can
                    be re-written for 0.002 ETH / 2 Matic. Prices are intended
                    to be low and may be reduced in the future if ETH price
                    increases, to keep NFT notes accessible to anyone.
                  </p>
                  <p style={styles.para}>
                  If you have questions, comments, or business inquiries, please contact <a  href="https://twitter.com/thinkingethmoji">@thinkingethmoji</a> on twitter!
                  </p>

                  <p style={styles.para}>
                  Twitter
                  <ul>
                    <li><a href="https://twitter.com/nftnotesio">@nftnotesio</a></li>
                    <li> <a  href="https://twitter.com/thinkingethmoji">@thinkingethmoji</a></li>
                  </ul>
                  </p>

                  <p style={styles.para}>
                  Contracts
                  <ul>
                    <li><a href="https://etherscan.io/address/0x9bf58e1c4b588c1718425a2a5e3bdbc392878420">Ethereum</a></li>
                    <li><a href="https://arbiscan.io/address/0x9bf58e1c4b588c1718425a2a5e3bdbc392878420">Arbitrum</a></li>
                    <li><a href="https://polygonscan.com/address/0x9bf58e1c4b588c1718425a2a5e3bdbc392878420">Polygon</a></li>
                  </ul>
                  </p>

                  <p style={styles.para}>
                  Opensea
                  <ul>
                  <li><a href="https://opensea.io/collection/nft-notes">Ethereum</a></li>
                    <li><a href="https://opensea.io/collection/nft-notes-v2">Arbitrum</a></li>
                    <li><a href="https://opensea.io/collection/nft-notes-v3">Polygon</a></li>
                  </ul>
                  </p>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};
const styles = {
  rootPaper: {
    p: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontWeight: 700,
  },

  contentBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  para: {
    marginTop: "32px",
    fontSize: "18px",
  },
};
