
import React, { Fragment } from "react";
import { MintNotes } from "./components/mint-notes";

export const MintPage = () => {

  return (
    <Fragment>
     <MintNotes />
      
    </Fragment>
  );
};
