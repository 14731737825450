import { Button, Dialog, DialogActions } from '@mui/material';
import React from 'react'
import EmojiPicker from 'emoji-picker-react';
export const EmojiDialog = (props) => {
    const {isOpen, onEmojiClick, closeDlg} = props;

    const handleClose = () => {
        closeDlg();
    }

    const handleEmoji = (emoji) =>{
        onEmojiClick(emoji.emoji)
    }
    return (
        <Dialog open={isOpen} onClose={handleClose} >
            
            <EmojiPicker skinTonesDisabled theme='dark' onEmojiClick={handleEmoji}/>
            <DialogActions>
                <Button  onClick={handleClose} color='secondary' variant='contained'>OK</Button>
            </DialogActions>
        </Dialog>
    );
}