import { createTheme } from "@mui/material";

export default createTheme({
    
    palette: {
        mode: "dark",
        primary:{
            main: "#344368",
            dark: "#344368",
            light: "#344368"
        },
        secondary: {
            main: "#FDB813",
            dark:"#FDB813"
        }
    }
})