import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
const RPC_URLS = {
    1: "https://eth-mainnet.g.alchemy.com/v2/sqS3iZFju8usmzD2bdHmVdgy-ONvEAzD",
    137: "https://arb-mainnet.g.alchemy.com/v2/cUeJlk5vWZ43R7kfX9-hjbZ-XA3sc9dq",
    42161:"https://arb-mainnet.g.alchemy.com/v2/cUeJlk5vWZ43R7kfX9-hjbZ-XA3sc9dq"
  }
export const walletconnect = new WalletConnectConnector({
    rpc: RPC_URLS,
    chainId: 1,
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true
  })