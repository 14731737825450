/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Select, MenuItem, useTheme } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { networks } from "../connectors/networks";
export const SelectNetwork = () => {
  const [networkId, setNetworkId] = React.useState("1000");
  const { account, chainId } = useWeb3React();

  const handleChange = async (event) => {
    setNetworkId(event.target.value);
    try {
      switch (event.target.value) {
        case networks.arbitrum.chainId:
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [networks.arbitrum],
          });
          break;
        case networks.ethereum.chainId:
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: networks.ethereum.chainId }],
          });

          break;
        case networks.polygon.chainId:
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [networks.polygon],
          });
          break;
        default:
          return;
      }
    } catch (err) {
      if(err.code && err.code===4001){
        setNetworkId(`0x${chainId.toString(16)}`);
      }
    }
  };
  const theme = useTheme();
  

  useEffect(() => {
    if (account) {
      console.log(chainId);
      setNetworkId(`0x${chainId.toString(16)}`);
    }
  }, [chainId]);

  const menuItems = [
    <MenuItem color="secondary" key="Ethereum" value={"0x1"}>
      Ethereum
    </MenuItem>,
    <MenuItem key="Arbitrum" value={"0xa4b1"}>
      Arbitrum
    </MenuItem>,
    <MenuItem key="Polygon" value={"0x89"}>
      Polygon
    </MenuItem>,
  ];
  return (
    <Box className="select-network" sx={{ ml: 2, mr: 2 }}>
      <Select
        color="secondary"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={networkId}
        sx={{
          height: "38px",
          border: `1px solid ${theme.palette.secondary.main}`,
          color: theme.palette.secondary.main,
          width: "160px",
        }}
        onChange={handleChange}
      >
        {account ? (
          menuItems.map((x) => x)
        ) : (
          <MenuItem color="secondary" value={1000}>
            Select Network
          </MenuItem>
        )}
      </Select>
    </Box>
  );
};
