import { Close } from "@mui/icons-material";
import {
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import { EditNotes } from "./edit-note";

export const EditNoteDialog = ({ note, isOpen, handleClose }) => {
  return (
    <Dialog fullScreen onClose={handleClose} open={isOpen}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`Edit ${note.title} #${note.tokenId}`}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            OK
          </Button>
        </Toolbar>
      </AppBar>
      <Box width="100%" sx={{p:2}}>
      <EditNotes note={note} />
      </Box>
    </Dialog>
  );
};
