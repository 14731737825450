import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNetwork } from "../../connectors/addresses";
import { setIsLoading } from "../my-notes-slice";
import { Alchemy, NftOrdering } from "alchemy-sdk";

export const getAllNfts = createAsyncThunk(
  "myNotes/getAllNfts",
  async ({ account, chainId }, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const network = getNetwork(chainId);
      const settings = {
        apiKey: network.KEY,
        network: network.AlchemyNetwork,
      };
console.log(network)
      const alchemy = new Alchemy(settings);
      const nfts = await alchemy.nft.getNftsForOwner(account, {
        contractAddresses: [network.CONTRACT],
        orderBy: NftOrdering.TRANSFERTIME,
      });
      return nfts.ownedNfts;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const refreshNftMetadata = createAsyncThunk(
  "myNotes/refreshNftMetadata",
  async ({chainId, tokenId, account}, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const network = getNetwork(chainId);
      const settings = {
        apiKey: network.KEY,
        network: network.AlchemyNetwork,
      };
      const alchemy = new Alchemy(settings);
      await alchemy.nft.refreshNftMetadata(network.CONTRACT,tokenId );
      thunkApi.dispatch(getAllNfts({chainId, account}));
      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
