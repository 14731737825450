import { configureStore } from '@reduxjs/toolkit';
import appReducer from "./app-slice";
import notesReducer from "./my-notes-slice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    myNotes: notesReducer
  },
});
