/* eslint-disable react-hooks/exhaustive-deps */

import { useWeb3React } from '@web3-react/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes , Route} from 'react-router-dom';
import './App.css';
import { getAllNfts } from './app/thunks/myNotes.thunks';
import Layout from "./layout/layout";
import { AboutPage } from './pages/about/about';
import { MintPage } from './pages/mint/mint';
import { MyNotesPage } from './pages/my-notes/my-notes';

function App() {
  const {account, chainId} = useWeb3React();
const dispatch = useDispatch();
  useEffect(() => {
    if(account && chainId){

      dispatch(getAllNfts({account, chainId}));
    }
  }, [account, chainId])
  return (
    <div className="App">
        <Layout >
          <Routes>
            <Route path="/" element={<MintPage />} />
            <Route path="/dashboard" element={<MyNotesPage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </Layout>
    </div>
  );
}

export default App;
